<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar flat>
        <div class="flex justify-between flex-wrap">
          <v-dialog v-model="dialog" max-width="600px" persistent>
            <template v-if="addStore" v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2 text-capitalize elevation-0"
                v-bind="attrs"
                v-on="on"
              >
                Create Store
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }} Company's Store</span>
              </v-card-title>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                @submit.prevent="saveStore"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <label for="">Store Name</label>
                      <v-text-field
                        v-if="editedIndex"
                        filled
                        color="info"
                        v-model="editedItem.name"
                        :rules="emptyRules"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        filled
                        color="info"
                        v-model="storeName"
                        :rules="emptyRules"
                      ></v-text-field>

                      <label for="">Store Address</label>
                      <v-textarea
                        v-if="editedIndex"
                        filled
                        color="info"
                        v-model="editedItem.address"
                        :rules="emptyRules"
                      />
                      <v-textarea
                        v-else
                        filled
                        color="info"
                        v-model="storeAddress"
                        :rules="emptyRules"
                      />
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions class="pb-3">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="elevation-0 text-capitalize text-caption text-sm-body-1"
                    color="error"
                    @click="closeModal"
                    >Cancel</v-btn
                  >
                  <v-btn
                    class="caption text-capitalize text-caption text-sm-body-1 elevation-0"
                    color="primary"
                    type="submit"
                    :loading="loading"
                    >{{ formTitle }} Store</v-btn
                  >
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
        <v-dialog v-model="dialogDelete" max-width="500px" persistent>
          <v-card class="py-5">
            <v-card-title class="text-body-1 d-flex justify-center"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="AddUserToStore" max-width="500px" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Assign User To {{ storeName }}</span>
            </v-card-title>
            <v-form ref="form" lazy-validation>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">User</label>
                    <v-select
                      v-model="asignUserId"
                      :items="users"
                      item-text="name"
                      item-value="id"
                      filled
                      label="Users"
                      :rules="emptyRules"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions class="pb-3">
                <v-spacer></v-spacer>
                <v-btn
                  class="elevation-0 text-capitalize text-caption text-sm-body-1"
                  color="error"
                  @click="closeModal"
                  >Cancel</v-btn
                >
                <v-btn
                  class="caption text-capitalize text-caption text-sm-body-1 elevation-0"
                  color="primary"
                  @click="saveAssignUser"
                  :loading="loading"
                  >Assign User</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogView" max-width="600px" persistent>
          <v-card>
            <v-card-title class="text-h6 my-2"
              >Company Store Details</v-card-title
            >
            <v-divider></v-divider>
            <v-container>
              <v-row class="my-3">
                <v-col cols="12">
                  <v-row>
                    <v-col cols="5" class="ml-3 font-weight-bold"
                      >Store Name</v-col
                    >
                    <v-col cols="6" class="text-right">
                      {{ detailPage.name }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="5" class="ml-3 font-weight-bold"
                      >Store Address</v-col
                    >
                    <v-col cols="6" class="text-right">
                      {{ detailPage.address }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="5" class="ml-3 font-weight-bold">Domain</v-col>
                    <v-col cols="6" class="text-right">
                      {{ detailPage.company.domain }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                @click="dialogView = false"
                class="elevation-0 my-2"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <mayventory-table
        :headers="headers"
        :items="items"
        item-key="name"
        title="Store"
        url="/app/store/view/"
      >
      </mayventory-table>
      <!-- </template>
    
  </v-data-table> -->
    </v-col>
  </v-row>
</template>
<script>
import {
  COMPANY_STORE,
  ADD_COMPANY_STORE,
  UPDATE_COMPANY_STORE,
  DELETE_COMPANY_STORE,
  GET_ALL_USERS,
  ASSIGN_USER_TO_STORE,
} from "../../../constants/graphql";
import "sweetalert2";
import store from "../../../store/index";
export default {
  name: "GetCompanyStoreList",
  metaInfo: {
    title: "COMPANY STORE",
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    AddUserToStore: false,
    userPermissions: store.state.authData.userPermissions,
    asignUserId: "",
    dialogView: false,
    headers: [
      {
        text: "S/N",
        align: "start",
        sortable: true,
        value: "id",
      },
      { text: "Name", value: "name", align: "start" },
      { text: "Address", value: "address", align: "start" },
      { text: "Actions", value: "action", align: "start" },
    ],
    items: [],
    editedItem: [],
    editedItemId: "",
    detailPage: {
      id: "",
      name: "",
      address: "",
      company: {
        domain: "",
      },
    },
    editedIndex: false,
    dialogName: "Create",
    loading: false,
    storeName: "",
    storeId: "",
    storeAddress: "",
    emptyRules: [(v) => !!v || "Field is required"],
    valid: true,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === false ? "Create" : "Edit";
    },
    addStore() {
      if (this.userPermissions.includes("add_store")) {
        return true;
      }
    },
  },

  created() {
    this.getStore();
    this.$root.$refs.S = this;
  },

  apollo: {
    items: {
      query: COMPANY_STORE,
      update: (result) => result.getCompanyStoreList,
      error(error) {
        handleError(error);
      },
    },
    users: {
      query: GET_ALL_USERS,
      update: (result) => result.getCompanyUsers,
      error(error) {
        handleError(error);
      },
    },
  },

  methods: {
    editItem(item) {
      this.editedItem = item;
      this.editedItemId = item.id;
      this.dialog = true;
      this.editedIndex = true;
    },

    deleteItem(item) {
      this.editedItem = item;
      this.editedItemId = item;
      this.dialogDelete = true;
      this.editedIndex = true;
    },

    assignUser(item) {
      this.AddUserToStore = true;
      this.storeId = item.id;
      this.storeName = item.name;
    },

    deleteItemConfirm() {
      this.$apollo
        .mutate({
          mutation: DELETE_COMPANY_STORE,
          variables: {
            storeId: parseInt(this.editedItemId),
          },
        })
        .then((res) => {
          this.$swal.fire(
            ` ${this.storeName}`,
            `${res.data.deleteStore.message}`,
            "success"
          );
          this.$apollo.queries.items.refetch();
        });
      this.closeDelete();
    },

    viewItem(item) {
      this.dialogView = true;
      this.detailPage = Object.assign({}, item);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedIndex = false;
    },

    // get Store
    getStore() {
      this.$apollo
        .query({
          query: COMPANY_STORE,
        })
        .then((data) => {
          this.items = data.data.getCompanyStoreList;
        });
    },

    saveAssignUser() {
      let validateInput = this.$refs.form.validate();
      if (validateInput) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: ASSIGN_USER_TO_STORE,
            variables: {
              userId: this.asignUserId,
              storeId: this.storeId,
            },
          })
          .then((data) => {
            this.$swal.fire(
              ` ${data.data.updateUserDetail.name}`,
              `has been assign to ${this.storeName} successfully!`
            );
            this.$apollo.queries.items.refetch();
            this.loading = false;
            this.reset();
            this.AddUserToStore = false;
          });
      }
    },
    // create store
    saveStore() {
      let validateInput = this.$refs.form.validate();
      if (this.editedIndex) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: UPDATE_COMPANY_STORE,
            variables: {
              storeId: this.editedItem.id,
              name: this.editedItem.name,
              address: this.editedItem.address,
            },
          })
          .then(() => {
            this.$swal.fire(
              ` ${this.editedItem.name}`,
              "was updated successfuly",
              "success"
            );
            this.$apollo.queries.items.refetch();
            this.dialog = false;
            this.loading = false;
            this.reset();
          });
      } else {
        if (validateInput) {
          this.loading = true;
          this.$apollo
            .mutate({
              mutation: ADD_COMPANY_STORE,
              variables: {
                name: this.storeName,
                address: this.storeAddress,
              },
            })
            .then(() => {
              this.$swal.fire(
                ` ${this.storeName}`,
                "was added successfuly",
                "success"
              );
              this.$apollo.queries.items.refetch();
              this.dialog = false;
              this.loading = false;
              this.reset();
            });
        }
      }
    },

    closeModal() {
      this.dialog = false;
      this.AddUserToStore = false;
      this.editedIndex = false;
    },

    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
